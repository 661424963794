const items = Array.from(document.querySelectorAll('.nt-feed__thumb'));
const focus = document.querySelector('#focus');
// for each groups get all
function toggleClickedClass(elem) {
  for (var i = 0; i < elem.length; i++) {
    elem[i].addEventListener("click", function(e) {
      var current = this;
      for (var i = 0; i < elem.length; i++) {
        if (current !== elem[i]) {
          elem[i].classList.remove('clicked');
        } else if (current.classList.contains('clicked') === true) {
          current.classList.remove('clicked');
        } else {
          current.classList.add('clicked');
        }
      }
      e.preventDefault();
    });
  }
}

toggleClickedClass(items);



function getSiblings(el, filter) {
  var siblings = [];
  el = el.parentNode.firstChild;
  do {
    if (!filter || filter(el)) {
      siblings.push(el);
    }
  } while (el = el.nextSibling);
  return siblings;
}

// example filter function
function exampleFilter(el) {
  return el.nodeName.toLowerCase() === 'section';
}

//get all items and return the JSON representation
items.forEach(function(item) {
  let url = item.children[0].href;
  let json = url + '.json';
  let div = document.createElement('div');
  div.classList.add('modal');

  item.addEventListener("click", function(e) {
    e.preventDefault();
    let created = document.querySelector('.modal');
    let siblings = Array.from(getSiblings(item.parentElement,
      exampleFilter));

    if (item.classList.contains('clicked')) {
      // Element exists, call removeChild on its parent

      item.parentElement.classList.add('open');
      siblings.forEach(function(sibling) {
        sibling.classList.add('sibling');
      });
    }
    if (!item.classList.contains('clicked')) {
      // Element exists, call removeChild on its parent

      item.parentElement.classList.remove('open');
      siblings.forEach(function(sibling) {
        sibling.classList.remove('sibling');
      });
    }

    if (document.body.contains(created)) {
      // Element exists, call removeChild on its parent

      created.parentElement.removeChild(created);

      item.appendChild(div);
      console.log(item);
      fetch(json)
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          div.innerHTML = response.html;
          if (document.querySelector('.nt-item__media')) {
            createFocus('.nt-item__media__link');
          }
        });
    } else {
      item.appendChild(div);
      fetch(json)
        .then(function(response) {
          return response.json();
        })
        .then(function(response) {
          div.innerHTML = response.html;
          if (document.querySelector('.nt-item__media')) {
            createFocus('.nt-item__media__link');
          }

        });

    }

  }, false);
});


//Make focus modal
const createFocus = function(array) {
  let links = Array.from(document.querySelectorAll(
    array));

  let div = document.createElement('div');
  div.classList.add('focus__inner');

  let close = document.createElement('div');
  close.classList.add('focus__inner__close');

  let closeIcon = document.createElement('span');
  closeIcon.classList.add('nt-icon', 'nt-icon__close');

  let closeLine1 = document.createElement('span');
  closeLine1.classList.add('nt-icon__close__line');

  let closeLine2 = document.createElement('span');
  closeLine2.classList.add('nt-icon__close__line');
  let closeLine3 = document.createElement('span');
  closeLine3.classList.add('nt-icon__close__line');

  closeIcon.appendChild(closeLine1);
  closeIcon.appendChild(closeLine2);
  closeIcon.appendChild(closeLine3);
  close.appendChild(closeIcon);

  let innerWrapper = document.createElement('div');
  let img = document.createElement('img');
  img.classList.add('focus__inner__wrapper__img');
  let caption = document.createElement('div');
  caption.classList.add('focus__inner__caption');
  innerWrapper.appendChild(img);
  innerWrapper.appendChild(caption);
  div.appendChild(close);
  div.appendChild(innerWrapper);
  links.forEach(function(link) {
    link.addEventListener("click", function(e) {
      e.preventDefault();
      let captionText = link.parentElement.lastElementChild.innerHTML;
      caption.innerHTML = captionText;
      let url = link.href;
      let classes = link.parentElement.classList;
      img.src = url;
      innerWrapper.setAttribute('class', classes);
      innerWrapper.classList.add('focus__inner__wrapper');
      focus.innerHTML = div.outerHTML;
      focus.style.display = 'block';
      console.log(link.parentElement.classList);
      let close = document.querySelector('.focus__inner__close');
      close.addEventListener("click", function(e) {
        focus.style.display = 'none';
        console.log('clicked');
      }, false);

    }, false);
  });
};
